var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Client } from "@twilio/conversations";
import { formatDistance } from "date-fns";
import { enCA, fr } from "date-fns/locale";
import $ from "jquery"; // Seems crazy but we need to show dot when new firebase notification arrives,and firebase conf is here
import { initFcmServiceWorker, subscribeFcmNotifications, showNotification, } from "./firebase/firebase";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
var NotificationsList = function (_a) {
    var _b, _c;
    var currentLanguage = _a.currentLanguage, channel = _a.channel, widgetDomID = _a.widgetDomID, widgetOpenedEvenListener = _a.widgetOpenedEvenListener, widgetClosedEvenListener = _a.widgetClosedEvenListener;
    var _d = __read(React.useState([]), 2), notifications = _d[0], setNotifications = _d[1];
    var _e = __read(React.useState(null), 2), deletingIndex = _e[0], setDeletingIndex = _e[1];
    var _f = __read(React.useState(null), 2), client = _f[0], setClient = _f[1];
    var _g = __read(React.useState(true), 2), hasMore = _g[0], setHasMore = _g[1];
    var _h = __read(React.useState(1), 2), page = _h[0], setPage = _h[1];
    var _j = __read(React.useState(null), 2), url = _j[0], setUrl = _j[1];
    var roomsUrl = (_b = document.getElementById("public-room-url")) === null || _b === void 0 ? void 0 : _b.value;
    var chatUrl = (_c = document.getElementById("twillio-chat-url")) === null || _c === void 0 ? void 0 : _c.value;
    var t = useTranslation(["notifications"]).t;
    React.useEffect(function () {
        var fcmInit = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, user, client, messaging;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/api/twillio/token/")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        user = _a.sent();
                        client = new Client(user.token);
                        setClient(client);
                        return [4 /*yield*/, initFcmServiceWorker()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, subscribeFcmNotifications(client)];
                    case 4:
                        messaging = _a.sent();
                        messaging.onMessage(function (payload) {
                            if (payload.notification) {
                                // Handle Push notifications
                                var notificationData_1 = JSON.parse(payload.notification.body);
                                $("#".concat(notificationData_1.notification_channel, "-new-message")).show();
                                if (notificationData_1.notification_channel == channel) {
                                    setNotifications(function (prevNotifications) { return __spreadArray([
                                        notificationData_1
                                    ], __read(prevNotifications), false); });
                                }
                            }
                            if (payload.data &&
                                payload.data.twi_message_type &&
                                payload.data.twi_message_type == "twilio.conversations.new_message") {
                                $("#messagerie-new-message").show();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        fcmInit().catch(function () {
            console.error("FCM initialization failed: no push notifications will be available");
        });
    }, [channel]);
    if (client) {
        client.on("pushNotification", function (event) {
            if (event.type != "twilio.conversations.new_message") {
                return;
            }
            if (Notification.permission === "granted") {
                showNotification(event);
            }
            else {
                console.log("Push notification is skipped", Notification.permission);
            }
        });
    }
    var timeAgoLocalized = function (dateString, locale) {
        var date = new Date(dateString);
        var now = new Date();
        var options = { addSuffix: true, locale: locale };
        return formatDistance(date, now, options);
    };
    var fetchInitialNotifications = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, unreadCount, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/notifications/?notification_channel=".concat(channel, "&page=1&per_page=10"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setNotifications(data.results);
                    setPage(2);
                    setHasMore(data.next && true);
                    unreadCount = data.results.filter(function (notif) { return notif.is_notification_read == false; });
                    if (unreadCount.length > 0) {
                        $("#".concat(channel, "-new-message")).show();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1, "Error fetching initial notifications");
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchNotifications = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/notifications/?notification_channel=".concat(channel, "&page=").concat(page, "&per_page=30"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data_1 = _a.sent();
                    if (data_1.results.length === 0) {
                        setHasMore(false);
                    }
                    else {
                        setNotifications(function (prevNotifications) {
                            return prevNotifications.concat(data_1.results);
                        });
                        setPage(function (prevPage) { return prevPage + 1; });
                        if (!data_1.next) {
                            setHasMore(false);
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    setHasMore(false); // Stop loader if there is an error
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var csrfToken = document.cookie.match(/csrftoken=([\w-]+)/)[1];
    var handleDelete = function (notificationId, index) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDeletingIndex(index);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/notifications/".concat(notificationId, "/"), {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": csrfToken,
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (response.status === 204) {
                        setNotifications(function (prevNotifications) {
                            return prevNotifications.filter(function (_, i) { return i !== index; });
                        });
                        setDeletingIndex(null);
                    }
                    else {
                        console.error("Error deleting notification with id ".concat(notificationId));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error("Error deleting notification with id ".concat(notificationId), error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var markNotificationsAsRead = function (visibleNotificationIds) { return __awaiter(void 0, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetch("/api/notifications/mark-as-read/", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": csrfToken,
                            },
                            body: JSON.stringify({ notification_ids: visibleNotificationIds }),
                        })];
                case 1:
                    _a.sent();
                    // Update notifications state to reflect the changes
                    setNotifications(function (prevNotifications) {
                        return prevNotifications.map(function (notification) {
                            if (visibleNotificationIds.includes(notification.id)) {
                                return __assign(__assign({}, notification), { is_notification_read: true });
                            }
                            return notification;
                        });
                    });
                    $("#".concat(channel, "-new-message")).hide();
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    console.log(error_4, "Error marking notifications as read");
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDropDownOpened = function () {
        fetchInitialNotifications();
    };
    React.useEffect(function () {
        var dropDownElement = document.getElementById(widgetDomID);
        dropDownElement.addEventListener(widgetOpenedEvenListener, handleDropDownOpened);
        return function () {
            //Unregister event when component unmount
            dropDownElement.removeEventListener(widgetOpenedEvenListener, handleDropDownOpened);
        };
    }, []);
    React.useEffect(function () {
        var dropDownElement = document.getElementById(widgetDomID);
        var handleDropDownClosed = function () {
            if (notifications.length > 0) {
                var visibleNotificationIds = notifications
                    .filter(function (notification) { return !notification.is_notification_read; })
                    .map(function (notification) { return notification.id; });
                // Call the API to mark the visible notifications as read
                if (visibleNotificationIds.length > 0) {
                    markNotificationsAsRead(visibleNotificationIds);
                }
            }
            setHasMore(true);
            setPage(1);
        };
        dropDownElement.addEventListener(widgetClosedEvenListener, handleDropDownClosed);
        var url = $("#".concat(channel, "-url")).val();
        setUrl(url);
        return function () {
            dropDownElement.removeEventListener(widgetClosedEvenListener, handleDropDownClosed);
        };
    }, [notifications]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "view-all-notifications" }, { children: _jsx("a", __assign({ href: channel == "forum" ? roomsUrl : chatUrl }, { children: t("View all") })) })), _jsx(InfiniteScroll, __assign({ dataLength: notifications.length, next: fetchNotifications, hasMore: hasMore, loader: _jsx("div", { className: "loader-topbar" }), height: 450, endMessage: _jsx("p", __assign({ className: "no-notifications" }, { children: t("There are no new notifications") })) }, { children: notifications.map(function (notification, index) { return (_jsxs("li", __assign({ className: "notification-element ".concat(!notification.is_notification_read && "unread") }, { children: [_jsxs("a", __assign({ href: channel == "messagerie"
                                ? "".concat(url, "?conversation_sid=").concat(notification.thread_id)
                                : url, className: "top-text-block ".concat(!notification.is_notification_read && "unread") }, { children: [_jsx("div", __assign({ className: "top-text-heading" }, { children: notification.body })), _jsx("div", __assign({ className: "top-text-light" }, { children: timeAgoLocalized(notification.created_at, currentLanguage == "en" ? enCA : fr) }))] })), deletingIndex === index ? (_jsx("div", { className: "loader-topbar m-0" })) : (_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cross.svg"), alt: "delete", className: "delete-notification", onClick: function () { return handleDelete(notification.id, index); } }))] }), index)); }) }))] }));
};
export default NotificationsList;
